/* Importando fotns externas */
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@400;600&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    /* Define como a fonte padrão para toda a aplicação */
    font-family: Bai Jamjuree, system-ui, sans-serif;
    scroll-behavior: smooth;
  }
  body{
    
  }
}